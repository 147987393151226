import * as yup from 'yup';
import { BasicBlockValidationSchema } from 'components/editor/schema';
import { ComponentTypes } from 'common/ComponentTypes';
import { ExpandingArrowButton } from 'components/button/ExpandingArrowButton';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { PlayCircleIcon } from '@heroicons/react/24/solid';
import { useHtmlParser } from 'hooks/useHtmlParser';
import { useState } from 'react';
import ResponsiveImage from './ResponsiveImage';
import VideoModal from 'components/modals/VideoModal';

export const BlockFormSchema: FormSchemaType = {
  videoUrl: {
    type: ComponentTypes.TextField,
    label: 'Video URL',
    required: true
  },
  imageUrl: {
    type: ComponentTypes.FileUpload,
    label: 'Image',
    required: true,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  title: {
    type: ComponentTypes.TextField,
    label: 'Button Label',
    required: false
  },
  videoLabel: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: false
  },
  includePlayBtn: {
    type: ComponentTypes.Toggle,
    label: 'Include Play Button',
    required: false
  },
  size: {
    type: ComponentTypes.Select,
    label: 'Button Size',
    required: false,
    additionalProps: {
      options: [
        { id: 'medium', name: 'Medium' },
        { id: 'large', name: 'Large' }
      ]
    }
  }
};

export const BlockSchema = BasicBlockValidationSchema.shape({
  videoUrl: yup.string().required(),
  imageUrl: yup.string().required(),
  title: yup.string().notRequired(),
  buttonLabel: yup.string().notRequired(),
  includePlayBtn: yup.boolean().notRequired(),
  size: yup.string().notRequired()
});
export interface VideoBoxProps {
  id: string;
  slug?: string;
  videoUrl: string;
  imageUrl: string;
  title?: string;
  notes?: string;
  videoLabel?: string;
  includePlayBtn?: boolean;
  buttonStyle?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'teal-outlined'
    | 'blue-background';
  buttonSize?: 'medium' | 'large';
  includeShareBtn?: boolean;
}

const VideoBox = (props: VideoBoxProps) => {
  const {
    id,
    slug,
    videoUrl,
    imageUrl,
    title,
    notes,
    videoLabel,
    includePlayBtn,
    includeShareBtn,
    buttonStyle = 'secondary',
    buttonSize = 'medium'
  } = props;

  const shareLinkBase = `${
    process.env.NEXT_PUBLIC_APP_URL || 'https://thereelhopeproject.org'
  }${includeShareBtn ? '/waiting-kids/meet' : '/videos'}`;
  const shareLink = slug
    ? `${shareLinkBase}/${slug.replace('meet-', '')}/`
    : `${shareLinkBase}/${id}/`;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const parsedNotes = useHtmlParser(notes || '');

  return (
    <>
      <div>
        {videoLabel && (
          <div className="text-center py-6 uppercase text-xl">{videoLabel}</div>
        )}
        <div
          className="cursor-pointer max-w-md mr-auto ml-auto h-fit relative"
          onClick={() => setIsOpen(true)}
        >
          <ResponsiveImage
            url={imageUrl}
            alt={title || videoLabel || 'video_box_image'}
          />
          {includePlayBtn && (
            <PlayCircleIcon className="h-24 w-24 absolute fill-white left-0 bottom-0 opacity-70" />
          )}
        </div>
        {title && (
          <div className="text-center py-6">
            <ExpandingArrowButton
              styletype={buttonStyle}
              onClick={() => setIsOpen(true)}
              label={title}
              arrowtype={'single'}
              size={buttonSize}
              overrides={{
                hoverBgColor: '#ffffff',
                hoverBorderColor: '#ffffff',
                arrowColor: '#00C4DE'
              }}
            />
          </div>
        )}
        {notes && <div className="text-center">{parsedNotes}</div>}
      </div>
      <VideoModal
        url={videoUrl}
        name={title || videoLabel || 'video_box_image'}
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        shareLink={shareLink}
      />
    </>
  );
};

export default VideoBox;
