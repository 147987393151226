import * as yup from 'yup';
import { ComponentTypes } from 'common/ComponentTypes';
import { Cta, CtaFormSchema, CtaProps, CtaSchema } from './Cta';
import { FormSchemaType } from 'common/types/FormSchemaType';
import ResponsiveImage from './ResponsiveImage';

export const BlockFormSchema: FormSchemaType = {
  image: {
    type: ComponentTypes.FileUpload,
    label: 'Background Image',
    required: true,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  cta: CtaFormSchema
};

export const BlockSchema = yup.object().shape({
  image: yup.string().required(),
  cta: CtaSchema
});
export interface MediaProps {
  image: string;
  cta: CtaProps;
}

const Media = (props: MediaProps) => {
  const { image, cta } = props;

  return (
    <div className="text-center">
      <div className="max-w-md mr-auto ml-auto">
        <ResponsiveImage url={image} alt="media_logo" grayScale={true} />
      </div>
      <div className="py-4 text-center">
        <Cta {...cta} newtab={true} />
      </div>
    </div>
  );
};

export default Media;
