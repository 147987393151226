import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import CollapsibleItem, { CollapsibleItemProps } from './CollapsibleItem';
import React, { useState } from 'react';
export interface CollapsibleGroupProps extends BasicBlockProps {
  title?: string;
  collapsibleItems: CollapsibleItemProps[];
  spacing?: number;
}

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: false
  },
  collapsibleItems: {
    type: ComponentTypes.Array,
    label: 'Items',
    additionalProps: {
      itemSchema: {
        label: {
          type: ComponentTypes.TextField,
          label: 'Label',
          required: true
        },
        content: {
          type: ComponentTypes.RichTextEditor,
          label: 'Content',
          required: true
        }
      }
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().notRequired(),
  collapsibleItems: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        content: yup.string().required()
      })
    )
    .required()
    .min(1)
});

const CollapsibleGroup = (props: CollapsibleGroupProps) => {
  const { title, collapsibleItems, spacing = '2.75%' } = props;
  const [openItem, setOpenItem] = useState<number | null>(null);
  const refs = React.useMemo(() => {
    return (
      collapsibleItems.map(() => {
        return React.createRef<HTMLButtonElement>();
      }) ?? []
    );
  }, [collapsibleItems]);

  const closeOthers = (curIndex: number) => {
    setOpenItem(curIndex);
    refs.forEach((ref, index) => {
      if (index === curIndex) return;
      const isOpen = ref.current?.getAttribute('data-open') === 'true';

      if (isOpen) {
        ref.current?.click();
      }
    });
  };
  return (
    <BlockWrapper {...props}>
      {title && (
        <h1 className="text-4xl text-center w-full my-5 uppercase">{title}</h1>
      )}
      <div className="max-w-5xl mx-auto">
        {collapsibleItems.map((item, index) => (
          <div key={index} style={{ marginBottom: spacing }}>
            <CollapsibleItem
              {...item}
              defaultOpen={index === openItem}
              onOpen={() => closeOthers(index)}
              groupref={refs[index]}
            />
          </div>
        ))}
      </div>
    </BlockWrapper>
  );
};
export default CollapsibleGroup;
