import {
  animate,
  motion,
  useInView,
  useMotionValue,
  useTransform
} from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

interface CounterItemProps {
  item: { label: string; count: string };
}

const CounterItem = (props: CounterItemProps) => {
  const { item } = props;
  const { count, label } = item;
  const [countVal, setCountVal] = useState<number>(0);
  const counter = useMotionValue(0);

  const rounded =
    typeof countVal === 'number'
      ? useTransform(counter, latest => {
          return Math.round(latest).toLocaleString();
        })
      : count;
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (count) {
      let c = !isNaN(Number(count.split(',').join('')))
        ? Number(count.split(',').join(''))
        : false;
      if (c) setCountVal(c);
    }
  }, [count]);

  // while in view animate the count
  useEffect(() => {
    if (inView && countVal) {
      animate(counter, countVal, { duration: 2 });
    }
  }, [counter, inView, count]);

  return (
    <div ref={ref} className="max-w-[550px] mx-auto">
      <motion.p
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false }}
        className="text-center text-trhp-blue text-6xl uppercase"
      >
        {rounded && !isNaN(Number(count.split(',').join('')))
          ? rounded
          : item.count}
      </motion.p>
      <h3 className="text-center text-trhp-blue 2xl:text-3xl xl:text-lg pb-4 font-josefin">
        {label}
      </h3>
    </div>
  );
};

export default CounterItem;
