import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { CtaFormSchema, CtaProps, CtaSchema } from './Cta';
import { ExpandingArrowButton } from 'components/button/ExpandingArrowButton';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useS3Upload } from 'hooks/useS3Upload';
import Image from 'next/image';
import Parser from 'html-react-parser';
import React from 'react';

export interface IconListProps extends BasicBlockProps {
  listItems: {
    icon: string;
    content: string;
    ctas?: CtaProps[];
  }[];
}

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  listItems: {
    type: ComponentTypes.Array,
    label: 'Items',
    additionalProps: {
      itemSchema: {
        icon: {
          type: ComponentTypes.FileUpload,
          label: 'Icon',
          required: true,
          additionalProps: {
            autoUpload: true,
            maxFiles: 1,
            tooltip: true,
            allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
          }
        },
        content: {
          type: ComponentTypes.RichTextEditor,
          label: 'Content',
          required: true
        },
        ctas: {
          type: ComponentTypes.Array,
          label: 'CTAs',
          required: false,
          additionalProps: {
            itemSchema: CtaFormSchema.children,
            max: 2
          }
        }
      }
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  listItems: yup
    .array()
    .of(
      yup.object({
        icon: yup.string().required(),
        content: yup.string().required(),
        ctas: yup.array().of(CtaSchema)
      })
    )
    .required()
    .min(1)
});

const IconList = (props: IconListProps) => {
  const { listItems } = props;
  const { cdnEndpoint } = useS3Upload({ bucket: 'trhp' });
  const router = useRouter();

  const getIconUrl = (url: string) => {
    if (url.includes('http')) return url;
    return `${cdnEndpoint}/${url}`;
  };

  return (
    <BlockWrapper {...props}>
      <div className="w-full">
        {listItems.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true, amount: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 py-7 align-middle"
          >
            <div className="col-span-1 text-[9rem] text-center items-center leading-none text-trhp-blue font-josefin font-extrabold">
              {index + 1}
            </div>
            <div
              className={`col-span-1 text-center inline-block ${
                item.ctas && item.ctas.length ? 'align-top' : 'align-middle'
              }`}
            >
              <Image
                className="mx-auto"
                src={getIconUrl(item.icon)}
                alt=""
                width="150"
                height="150"
              />
            </div>
            <div
              className={`col-span-4 flex-wrap text-xl font-light ${
                item.ctas && item.ctas.length
                  ? 'pt-7'
                  : 'py-7 md:py-0 inline-flex items-center'
              }`}
            >
              <div className="w-full">{Parser(item.content || '')}</div>
              {item.ctas && item.ctas.length > 0 && (
                <div className="flex w-full justify-center pt-10">
                  {item.ctas?.map((cta, i) => {
                    return (
                      <ExpandingArrowButton
                        key={i}
                        onClick={() =>
                          cta.url.includes('http')
                            ? window.open(cta.url)
                            : router.push(cta.url)
                        }
                        styletype={cta.type}
                        overrides={cta.overrides}
                        className="ml-6"
                        label={cta.label}
                        arrowtype={'single'}
                        size={cta.size || 'medium'}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </BlockWrapper>
  );
};
export default IconList;
