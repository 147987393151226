import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { FormSchemaType } from 'common/types/FormSchemaType';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({});

export const BlockSchema = BasicBlockValidationSchema.shape({});
export interface HorizontalLineProps extends BasicBlockProps {}

const HorizontalLine = (props: HorizontalLineProps) => {
  let backGroundColor = '#1e365c';
  if (props.textColor) {
    backGroundColor = props.textColor;
  }
  return (
    <BlockWrapper {...props}>
      <hr
        className={'h-[2px] border-0'}
        style={{ backgroundColor: backGroundColor }}
      />
    </BlockWrapper>
  );
};

export default HorizontalLine;
