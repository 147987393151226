import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  url: {
    type: ComponentTypes.TextField,
    label: 'Video URL',
    required: true
  },
  name: {
    type: ComponentTypes.TextField,
    label: 'Video Name',
    required: true
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  url: yup.string().required(),
  name: yup.string().required()
});
export interface VideoProps extends BasicBlockProps {
  url: string;
  name: string;
}

const Video = (props: VideoProps) => {
  const { url, name } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const defaultHeight = 495;
  const [videoHeight, setVideoHeight] = useState<number>(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  );

  const handleChangeVideoWidth = useCallback(() => {
    // const ratio =
    //   window.innerWidth > 990
    //     ? 1.0
    //     : window.innerWidth > 522
    //     ? 1.2
    //     : window.innerWidth > 400
    //     ? 1.45
    //     : 1.85;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    // console.log('height', iframeRef.current?.offsetWidth, height);
    return setVideoHeight(height);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleChangeVideoWidth);
    return function cleanup() {
      window.removeEventListener('resize', handleChangeVideoWidth);
    };
  }, []);

  useEffect(() => {
    handleChangeVideoWidth();
  }, [videoHeight]);
  return (
    <BlockWrapper {...props}>
      <div
        className="relative w-full lg:max-w-[80%] mx-auto text-center"
        style={{ paddingTop: videoHeight }}
      >
        <iframe
          src={url}
          ref={iframeRef}
          className="absolute top-0 left-0 w-full h-full"
          frameBorder="0"
          name={name}
          allow="autoplay; fullscreen; picture-in-picture"
          //@ts-ignore
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        />
      </div>
    </BlockWrapper>
  );
};

export default Video;
