import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { Cta, CtaFormSchema, CtaProps, CtaSchema } from './Cta';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { motion } from 'framer-motion';
import Parser from 'html-react-parser';
import ResponsiveImage from './ResponsiveImage';
export interface ThreeBoxComponentProps extends BasicBlockProps {
  title: string;
  subtitle: string;
  content: string;
  items: {
    imageUrl: string;
    text: string;
  }[];
  cta: CtaProps;
}

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: true
  },
  subtitle: {
    type: ComponentTypes.TextField,
    label: 'Subtitle',
    required: true
  },
  content: {
    type: ComponentTypes.RichTextEditor,
    label: 'Content',
    required: true
  },
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    max: 3,
    additionalProps: {
      itemSchema: {
        imageUrl: {
          type: ComponentTypes.FileUpload,
          label: 'Image',
          required: false,
          additionalProps: {
            autoUpload: true,
            maxFiles: 1,
            tooltip: true,
            allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
          }
        },
        text: {
          type: ComponentTypes.TextField,
          label: 'Text',
          required: true
        }
      }
    }
  },
  cta: CtaFormSchema
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().required(),
  subtitle: yup.string().required(),
  items: yup
    .array()
    .of(
      yup.object({
        imageUrl: yup.string().required(),
        text: yup.string().required()
      })
    )
    .required()
    .min(1),
  cta: CtaSchema
});

const ThreeBoxComponent = (props: ThreeBoxComponentProps) => {
  const { title, subtitle, content, items, cta } = props;
  return (
    <BlockWrapper {...props}>
      <h2 className={'text-center text-5xl pb-2 font-josefin'}>{title}</h2>
      <h4 className="pb-4 text-2xl text-center font-josefin">{subtitle}</h4>
      <p className="text-center text-xl font-light pb-4">
        {Parser(content || '')}
      </p>
      <div className="mt-10 mx-auto lg:columns-3 h-auto lg:flex lg:justify-around mb-10">
        {items.map((item, ind) => {
          return (
            <div
              key={ind}
              className="lg:max-w-[33%] lg:pr-6 lg:pl-6 text-center"
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
                exit={{ opacity: 0 }}
                className="w-20 h-20 mr-auto ml-auto mb-6 overflow-auto mt-6"
              >
                <ResponsiveImage url={item.imageUrl} alt={'image_for_3_comp'} />
              </motion.div>
              <div className="overflow-auto">{item.text}</div>
            </div>
          );
        })}
      </div>
      <div className="text-center">
        <Cta {...cta} />
      </div>
    </BlockWrapper>
  );
};

export default ThreeBoxComponent;
