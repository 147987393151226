export const makeEvenSpread = (numCol: number, maxNum: number): number[] => {
  // Length of final array. This is fixed so we can calculate it up front.
  const len = Math.ceil(numCol / maxNum);

  // An array of that length, filled with the maxNum. Probably this overflows
  // the numColumns constraint, which we fix in the next step.
  const overflowed = new Array(len).fill(maxNum);

  // This is how much the overflowed array's sum exceeds numCol.
  let over = len * maxNum - numCol;

  // Now you need to go through the array and subtract one from each element
  // in turn, until we hit the desired sum. If we get to the end of the array,
  // start again from the beginning.
  let ind = 0;
  while (over--) {
    overflowed[ind++ % len]--;
  }

  // You want the smaller numbers at the end, so reverse the result.
  return overflowed.reverse();
};
export const sliceIntoChunks = (arr: any[], chunkSize: number): any[] => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};
