import { Cta, CtaProps } from '../Cta';
import { motion } from 'framer-motion';
import Parser from 'html-react-parser';
import ResponsiveImage from '../ResponsiveImage';
import Video from '../Video';
import tw from 'tailwind-styled-components';
export interface SideContentProps {
  headline?: string;
  headlineSize?: string;
  textAlign: string;
  body?: string;
  button?: CtaProps;
  imageUrl?: string;
  videoUrl?: string;
  animate?: boolean;
  textShadow?: boolean;
}
interface StyledTextContainerProps {
  textAlign: string;
}
const StyledTextContainer = tw.div`
  ${(props: StyledTextContainerProps) => props.textAlign}
  relative
  h-auto
  lg:top-1/2
  lg:-translate-y-1/2
  flex-wrap
  p-6
  px-14
`;

const SideContentWrapper = tw.div`
  flex flex-col w-full lg:w-1/2
`;

export const SideContent = (props: SideContentProps) => {
  const {
    headline,
    headlineSize,
    textAlign,
    body,
    button,
    imageUrl,
    videoUrl,
    animate,
    textShadow
  } = props;

  if (imageUrl) {
    return (
      <SideContentWrapper>
        <div className="w-full aspect-video relative inline-block">
          <ResponsiveImage url={imageUrl} alt="reel_hope_image" />
          <div className="text-center">
            {button && button.url ? (
              <Cta
                type={button.type}
                url={button.url}
                overrides={button.overrides}
                label={button.label.toUpperCase()}
                className="mt-6 mb-6"
              />
            ) : null}
          </div>
        </div>
      </SideContentWrapper>
    );
  }
  if (videoUrl) {
    return (
      <SideContentWrapper>
        <div className="w-full aspect-video relative inline-block">
          <Video url={videoUrl} name={'reel_hope_video'} />
          <div className="text-center">
            {button ? (
              <Cta
                type={button.type}
                url={button.url}
                overrides={button.overrides}
                label={button.label.toUpperCase()}
                className="mt-6 mb-6"
              />
            ) : null}
          </div>
        </div>
      </SideContentWrapper>
    );
  }
  const headlingPadding = headlineSize
    ? headlineSize === 'text-6xl'
      ? 'pb-7'
      : 'pb-3'
    : 'pb-1';
  return (
    <SideContentWrapper>
      <div className="flex-1">
        <StyledTextContainer textAlign={textAlign}>
          <motion.div
            {...(animate && {
              initial: { opacity: 0, y: 50 },
              whileInView: { opacity: 1, y: 0 },
              transition: { delay: 0.2, duration: 0.7 }
            })}
          >
            <div
              style={{
                textShadow: textShadow ? '0 1px 3px rgba(0,0,0,.3)' : ''
              }}
            >
              <h1
                className={`font-josefin ${
                  headlineSize ?? 'text-xl lg:text-4xl'
                } uppercase ${headlingPadding}`}
              >
                {Parser(headline || '')}
              </h1>
              <div className="body-text">{Parser(body || '')}</div>
            </div>
          </motion.div>
        </StyledTextContainer>
      </div>
      <div className="text-center">
        {button && button.url && button.url !== '' ? (
          <Cta
            {...button}
            type={button.type}
            url={button.url}
            label={button.label.toUpperCase()}
            className="mt-6 mb-6"
          />
        ) : null}
      </div>
    </SideContentWrapper>
  );
};
