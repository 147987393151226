import * as yup from 'yup';
import { ComponentTypes } from 'common/ComponentTypes';
import {
  ExpandingArrowButton,
  OverrideProps
} from 'components/button/ExpandingArrowButton';
import { useRouter } from 'next/router';

export const CtaFormSchema = {
  type: ComponentTypes.NestedGroup,
  label: 'CTA Button',
  required: false,
  children: {
    type: {
      type: ComponentTypes.Select,
      label: 'Button Type',
      required: false,
      additionalProps: {
        options: [
          { id: 'primary', name: 'Primary' },
          { id: 'secondary', name: 'Secondary' },
          { id: 'tertiary', name: 'Tertiary' },
          { id: 'teal-outlined', name: 'Teal Outlined' },
          { id: 'blue-background', name: 'Blue Background' }
        ]
      }
    },
    url: {
      type: ComponentTypes.TextField,
      label: 'URL',
      required: false
    },
    label: {
      type: ComponentTypes.TextField,
      label: 'CTA Label'
    },
    arrowtype: {
      type: ComponentTypes.Select,
      label: 'Button Arrow Type',
      required: false,
      additionalProps: {
        options: [
          { id: 'single', name: 'Single Arrow' },
          { id: 'double', name: 'Double Arrow' }
        ]
      }
    },
    newTab: {
      type: ComponentTypes.Checkbox,
      label: 'Open In New Window',
      required: false
    },
    size: {
      type: ComponentTypes.Select,
      label: 'Button Size',
      required: false,
      additionalProps: {
        options: [
          { id: 'medium', name: 'Medium' },
          { id: 'large', name: 'Large' }
        ]
      }
    },
    overrides: {
      type: ComponentTypes.NestedGroup,
      label: 'Overrides',
      required: false,
      children: {
        bgColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Background Color',
          required: false
        },
        textColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Text Color',
          required: false
        },
        borderColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Border Color',
          required: false
        },
        hoverBgColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Hover Background Color',
          required: false
        },
        hoverTextColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Hover Text Color',
          required: false
        },
        hoverBorderColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Hover Border Color',
          required: false
        },
        arrowColor: {
          type: ComponentTypes.ColorPicker,
          label: 'Arrow Color',
          required: false
        }
      }
    }
  }
};
export const CtaSchema = yup.object().shape({
  type: yup.string().notRequired(),
  url: yup.string().notRequired(),
  label: yup.string().notRequired(),
  size: yup.string().notRequired(),
  arrowtype: yup.string().notRequired(),
  newTab: yup.boolean().notRequired()
});
export interface CtaProps {
  className?: string;
  url: string;
  label: string;
  newtab?: boolean;
  arrowtype?: 'single' | 'double';
  type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'teal-outlined'
    | 'blue-background';
  overrides?: OverrideProps;
  size?: 'medium' | 'large';
}

export const Cta = (props: CtaProps) => {
  const router = useRouter();
  const {
    url,
    label,
    type,
    className,
    newtab,
    arrowtype = 'single',
    overrides,
    size = 'medium'
  } = props;
  const handleClick = () => {
    if (newtab) {
      window.open(url);
      return;
    }
    router.push(url);
  };

  return (
    <ExpandingArrowButton
      styletype={type}
      onClick={handleClick}
      className={className}
      label={label}
      arrowtype={arrowtype}
      overrides={overrides}
      size={size}
    />
  );
};
