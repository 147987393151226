import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { useHtmlParser } from 'hooks/useHtmlParser';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  embedCode: {
    type: ComponentTypes.TextArea,
    label: 'Embed Code',
    required: true
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  embedCode: yup.string().required()
});

export interface EmbedBlockProps extends BasicBlockProps {
  embedCode: string;
}
const EmbedBlock = (props: EmbedBlockProps) => {
  const parsedBody = useHtmlParser(props.embedCode || '');

  return (
    <BlockWrapper {...props}>
      <div className="w-full flex embedded-content justify-center">
        {parsedBody}
      </div>
    </BlockWrapper>
  );
};

export default EmbedBlock;
