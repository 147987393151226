import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import Image from 'next/image';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: true
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().required()
});

export interface BlockProps extends BasicBlockProps {
  title: string;
}

const LogoHeader = (props: BlockProps) => {
  return (
    <BlockWrapper {...props}>
      <section className="w-full bg-trhp-teal pt-8 pb-5 text-center">
        <Image
          src={'/white-logo.png'}
          width={458}
          height={133}
          alt="The Reel Hope Project"
          className="mx-auto mb-7 w-3/5 md:w-[458px]"
        />
        <h6 className="text-white uppercase font-josefin text-xl md:text-2xl">
          {props.title}
        </h6>
      </section>
    </BlockWrapper>
  );
};

export default LogoHeader;
