import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { useEffect, useState } from 'react';
import { useS3Upload } from 'hooks/useS3Upload';
import CounterItem from './CounterItem';
export interface CounterProps extends BasicBlockProps {
  backgroundImage: string;
  title: string;
  items: {
    count: string;
    label: string;
  }[];
}
export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: false
  },
  backgroundImage: {
    type: ComponentTypes.FileUpload,
    label: 'Background Image',
    required: false,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    max: 3,
    additionalProps: {
      itemSchema: {
        label: {
          type: ComponentTypes.TextField,
          label: 'Label',
          required: true
        },
        count: {
          type: ComponentTypes.TextField,
          label: 'Count',
          required: true
        }
      }
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().notRequired(),
  backgroundImage: yup.string().notRequired(),
  items: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        count: yup.string().required()
      })
    )
    .required()
    .min(1)
});

const Counter = (props: CounterProps) => {
  const { backgroundImage, title, items } = props;
  const [imageUrl, setImageUrl] = useState<string>('');
  const { getFileUrl } = useS3Upload({
    bucket: process.env.NEXT_PUBLIC_S3_BUCKET || 'trhp'
  });
  const updateImageUrl = async () => {
    const fileUrl = await getFileUrl(backgroundImage);
    setImageUrl(fileUrl);
  };
  useEffect(() => {
    if (backgroundImage.includes('pages')) {
      updateImageUrl();
    } else {
      setImageUrl(backgroundImage);
    }
  }, [backgroundImage]);
  //bg-gradient-to-b from-[#f5f9ff] to-[rgba(245,249,255,0.66)]
  //linear-gradient(180deg,#f5f9ff 0%,rgba(245,249,255,0.66) 100%),url(https://www.thereelhopeproject.org/wp-content/uploads/2020/05/IMG_0029-scaled.jpg)!important;
  return (
    <BlockWrapper {...props}>
      <div
        className="w-full py-16 px-8 bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(180deg,#f5f9ff 0%,rgba(245,249,255,0.66) 100%),url(${imageUrl})`
        }}
      >
        <div className="">
          <h2 className="text-center uppercase text-trhp-blue text-5xl pb-4 font-josefin">
            {title}
          </h2>
          <div className="md:columns-3 py-12">
            {items.map((item, i) => {
              return <CounterItem item={item} key={i} />;
            })}
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default Counter;
