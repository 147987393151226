import * as yup from 'yup';
import {
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { BlockFormSchema as VideoBoxBlockFormSchema } from 'components/blocks/VideoBox';
import VideoBox, { VideoBoxProps } from './VideoBox';
import tw from 'tailwind-styled-components';

const sizeOptions: string[] = [
  'gap-x-0',
  'gap-x-0.5',
  'gap-x-1',
  'gap-x-1.5',
  'gap-x-2',
  'gap-x-2.5',
  'gap-x-3',
  'gap-x-3.5',
  'gap-x-4',
  'gap-x-5',
  'gap-x-6',
  'gap-x-7',
  'gap-x-8',
  'gap-x-9',
  'gap-x-10',
  'gap-x-11',
  'gap-x-12',
  'gap-x-14',
  'gap-x-16',
  'gap-x-20',
  'gap-x-24',
  'gap-x-28',
  'gap-x-32',
  'gap-x-36',
  'gap-x-40',
  'gap-x-44',
  'gap-x-48',
  'gap-x-52',
  'gap-x-56',
  'gap-x-60',
  'gap-x-64',
  'gap-x-72',
  'gap-x-80',
  'gap-x-96'
];

const colOptions: string[] = [
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4'
];

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  gap: {
    type: ComponentTypes.Select,
    label: 'Gap',
    additionalProps: {
      defaultValue: `gap-x-16`,
      options: sizeOptions.map(size => {
        const sizeParts = size.split('-');
        const nameVal = sizeParts[2];
        return {
          id: size,
          name: nameVal
        };
      })
    }
  },
  itemsPerRow: {
    type: ComponentTypes.Select,
    label: 'Items Per Row',
    required: true,
    additionalProps: {
      defaultValue: `grid-cols-2`,
      options: colOptions.map(col => {
        const colParts = col.split('-');
        const nameVal = colParts[2];
        return {
          id: col,
          name: nameVal
        };
      })
    }
  },
  buttonStyle: {
    type: ComponentTypes.Select,
    label: 'Button Style',
    required: true,
    additionalProps: {
      options: [
        { id: 'primary', name: 'Primary' },
        { id: 'secondary', name: 'Secondary' },
        { id: 'tertiary', name: 'Tertiary' },
        { id: 'teal-outlined', name: 'Teal Outlined' },
        { id: 'blue-background', name: 'Blue Background' }
      ]
    }
  },
  size: {
    type: ComponentTypes.Select,
    label: 'Button Size',
    required: false,
    additionalProps: {
      options: [
        { id: 'medium', name: 'Medium' },
        { id: 'large', name: 'Large' }
      ]
    }
  },
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    required: true,
    additionalProps: {
      itemSchema: VideoBoxBlockFormSchema
    }
  }
});

const VideoSchema = yup.object({
  videoUrl: yup.string().required(),
  imageUrl: yup.string().required(),
  title: yup.string().notRequired(),
  videoLabel: yup.string().notRequired(),
  includePlayBtn: yup.boolean().notRequired()
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  itemsPerRow: yup.string().required(),
  buttonStyle: yup.string().required(),
  items: yup.array().of(VideoSchema)
});

export interface VideoBoxSectionProps {
  gap?: string;
  itemsPerRow: string;
  buttonStyle:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'teal-outlined'
    | 'blue-background';
  includePlayBtn?: boolean;
  items: VideoBoxProps[];
  size: 'medium' | 'large';
  includeShareBtn?: boolean;
}
interface StyledListProps {
  itemsPerRow: string;
  gap: string;
}

const StyledList = tw.ul`
${(props: StyledListProps) => `${props.gap} lg:${props.itemsPerRow}`}
  mx-auto
  mt-20
  grid
  max-w-2xl
  grid-cols-1
  gap-y-16
  sm:grid-cols-2
  lg:mx-0
  lg:max-w-none
`;

const VideoBoxSection = (props: VideoBoxSectionProps) => {
  const {
    items,
    itemsPerRow,
    buttonStyle,
    includePlayBtn = false,
    includeShareBtn = false,
    gap = '8',
    size = 'medium'
  } = props;
  return (
    <BlockWrapper {...props}>
      <div className="mx-auto px-6 lg:px-8">
        <StyledList role="list" gap={gap} itemsPerRow={itemsPerRow}>
          {items.map((item, index) => (
            <li key={index}>
              <VideoBox
                {...item}
                includePlayBtn={includePlayBtn}
                buttonStyle={buttonStyle}
                buttonSize={size}
                includeShareBtn={includeShareBtn}
              />
            </li>
          ))}
        </StyledList>
      </div>
    </BlockWrapper>
  );
};

export default VideoBoxSection;
