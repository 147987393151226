import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { Cta, CtaFormSchema, CtaProps, CtaSchema } from './Cta';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { motion } from 'framer-motion';
import Parser from 'html-react-parser';
import ResponsiveImage from './ResponsiveImage';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  backgroundImage: {
    type: ComponentTypes.FileUpload,
    label: 'Background Image',
    required: true,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  title: {
    type: ComponentTypes.RichTextEditor,
    label: 'Title',
    required: true
  },
  body: {
    type: ComponentTypes.RichTextEditor,
    label: 'Body'
  },
  cardBgColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Card Background Color',
    required: false
  },
  cta: CtaFormSchema,
  shrink: {
    type: ComponentTypes.Checkbox,
    label: 'Shrink To Content',
    required: false
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  backgroundImage: yup.string().required(),
  title: yup.string().required(),
  body: yup.string().notRequired(),
  cardBgColor: yup.string().notRequired(),
  cta: CtaSchema.notRequired(),
  shrink: yup.boolean().notRequired()
});
export interface HeroProps extends BasicBlockProps {
  backgroundImage: string;
  cardBgColor: string;
  title: string;
  body?: string;
  cta?: CtaProps;
  shrink?: boolean;
}

const Hero = (props: HeroProps) => {
  const { backgroundImage, title, body, cta, cardBgColor, textColor, shrink } =
    props;

  return (
    <BlockWrapper {...props}>
      <div className="relative t-0 l-0 z-0 min-h-[400px]">
        <ResponsiveImage url={backgroundImage} alt={title} />
      </div>
      <div
        className={`z-1 absolute top-[50%] ${
          shrink ? '' : 'w-[75%]'
        } left-[50%] -translate-x-[50%] -translate-y-[50%]`}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true, amount: 1 }}
          transition={{ duration: 1 }}
        >
          <div
            className="bg-white p-8"
            style={{
              ...(cardBgColor && { backgroundColor: cardBgColor })
            }}
          >
            <h2
              className="w-full pb-4 font-josefin"
              style={{ ...(textColor && { color: textColor }) }}
            >
              {Parser(title)}
            </h2>
            {body && (
              <p className="text-center text-trhp-gray pb-4 body-text">
                {Parser(body || '')}
              </p>
            )}
            {cta && cta.label && (
              <div className="text-center mt-4">
                <Cta {...cta} />
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </BlockWrapper>
  );
};

export default Hero;
