import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import Profile, {
  BlockFormSchema as ProfileItemBlockFormSchema,
  BlockSchema as ProfileItemBlockSchema,
  ProfileProps
} from 'components/blocks/Profile';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Name',
    required: true
  },
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    required: true,
    additionalProps: {
      itemSchema: ProfileItemBlockFormSchema
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().notRequired(),
  items: yup.array().of(ProfileItemBlockSchema)
});
export interface ProfileSectionProps extends BasicBlockProps {
  title?: string;
  items: ProfileProps[];
}
const ProfileSection = (props: ProfileSectionProps) => {
  const { title, items } = props;
  return (
    <BlockWrapper {...props}>
      <div className="mx-auto px-6 lg:px-24">
        {title && (
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl">
            {title}
          </h2>
        )}
        <ul
          role="list"
          className="mx-auto mt-20 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4"
        >
          {items.map(item => (
            <li key={item.name} className="max-w-[320px] mx-auto">
              <Profile {...item} />
            </li>
          ))}
        </ul>
      </div>
    </BlockWrapper>
  );
};

export default ProfileSection;
