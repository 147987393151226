import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from '../BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { DotButton, NextButton, PrevButton } from './CarouselButtons';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { useCallback, useEffect, useState } from 'react';
import SideBySide, {
  SideBySideFormSchema,
  SideBySideProps,
  SideBySideSchema
} from '../SideBySide';
import useEmblaCarousel from 'embla-carousel-react';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: true
  },
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    required: true,
    additionalProps: {
      itemSchema: SideBySideFormSchema
    }
  }
});
export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().required(),
  items: yup.array().of(SideBySideSchema)
});
export interface CarouselProps extends BasicBlockProps {
  title: string;
  items: SideBySideProps[];
}

const Carousel = (props: CarouselProps) => {
  const { title, items } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <BlockWrapper {...props}>
      <div
        className={`${items[0].backgroundColor} ${items[0].textColor} lg:max-h-[778px]`}
      >
        <h2 className="text-5xl text-center pt-6 uppercase">{title}</h2>
        <>
          <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                {items.map((item, index) => {
                  return (
                    <div className="embla__slide" key={index}>
                      <div
                        className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                        data-te-carousel-active
                        data-te-carousel-item
                        style={{ backfaceVisibility: 'hidden' }}
                        key={index}
                      >
                        <SideBySide
                          leftContent={item.leftContent}
                          rightContent={item.rightContent}
                          backgroundColor={item.backgroundColor}
                          textColor={item.textColor}
                          animate={true}
                          textShadow={item.textShadow}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </div>

          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
        </>
      </div>
    </BlockWrapper>
  );
};

export default Carousel;
