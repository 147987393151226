/*
'pt-0',
    'pt-0.5',
    'pt-1',
    'pt-1.5',
    'pt-2',
    'pt-2.5',
    'pt-3',
    'pt-3.5',
    'pt-4',
    'pt-5',
    'pt-6',
    'pt-7',
    'pt-8',
    'pt-9',
    'pt-10',
    'pt-11',
    'pt-12',
    'pt-14',
    'pt-16',
    'pt-20',
    'pt-24',
    'pt-28',
    'pt-32',
    'pt-36',
    'pt-40',
    'pt-44',
    'pt-48',
    'pt-52',
    'pt-56',
    'pt-60',
    'pt-64',
    'pt-72',
    'pt-80',
    'pt-96',
    'pb-0',
    'pb-0.5',
    'pb-1',
    'pb-1.5',
    'pb-2',
    'pb-2.5',
    'pb-3',
    'pb-3.5',
    'pb-4',
    'pb-5',
    'pb-6',
    'pb-7',
    'pb-8',
    'pb-9',
    'pb-10',
    'pb-11',
    'pb-12',
    'pb-14',
    'pb-16',
    'pb-20',
    'pb-24',
    'pb-28',
    'pb-32',
    'pb-36',
    'pb-40',
    'pb-44',
    'pb-48',
    'pb-52',
    'pb-56',
    'pb-60',
    'pb-64',
    'pb-72',
    'pb-80',
    'pb-96',
    'pl-0',
    'pl-0.5',
    'pl-1',
    'pl-1.5',
    'pl-2',
    'pl-2.5',
    'pl-3',
    'pl-3.5',
    'pl-4',
    'pl-5',
    'pl-6',
    'pl-7',
    'pl-8',
    'pl-9',
    'pl-10',
    'pl-11',
    'pl-12',
    'pl-14',
    'pl-16',
    'pl-20',
    'pl-24',
    'pl-28',
    'pl-32',
    'pl-36',
    'pl-40',
    'pl-44',
    'pl-48',
    'pl-52',
    'pl-56',
    'pl-60',
    'pl-64',
    'pl-72',
    'pl-80',
    'pl-96',
    'pr-0',
    'pr-0.5',
    'pr-1',
    'pr-1.5',
    'pr-2',
    'pr-2.5',
    'pr-3',
    'pr-3.5',
    'pr-4',
    'pr-5',
    'pr-6',
    'pr-7',
    'pr-8',
    'pr-9',
    'pr-10',
    'pr-11',
    'pr-12',
    'pr-14',
    'pr-16',
    'pr-20',
    'pr-24',
    'pr-28',
    'pr-32',
    'pr-36',
    'pr-40',
    'pr-44',
    'pr-48',
    'pr-52',
    'pr-56',
    'pr-60',
    'pr-64',
    'pr-72',
    'pr-80',
    'pr-96'
  */
interface BlockWrapperProps {
  padding?: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  containerSize?: string;
  backgroundColor?: string;
  textColor?: string;
  children: React.ReactNode;
}
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const BlockWrapper = (props: BlockWrapperProps) => {
  const { padding, backgroundColor, textColor, children, containerSize } =
    props;
  const container = containerSize ?? 'w-full';
  return (
    <section
      className="w-full"
      style={{ background: backgroundColor, color: textColor }}
    >
      <div
        className={classNames(
          padding?.top,
          padding?.bottom,
          padding?.left,
          padding?.right,
          container,
          'relative',
          'mx-auto'
        )}
      >
        {children}
      </div>
    </section>
  );
};
