import { Form, Formik } from 'formik';
import { FormActions } from './FormActions';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { ObjectSchema } from 'yup';
import { OverrideProps } from 'components/button/ExpandingArrowButton';
import { SchemaFormElement } from './SchemaFormElement';
import React from 'react';
export interface SchemaFormProps {
  schema: FormSchemaType;
  defaultValues: any;
  validationSchema: ObjectSchema<any>;
  onSubmit: (values: any) => void;
  uploadPrefix?: string;
  saveText?: string;
  cancelPath?: string;
  buttonOverrides?: OverrideProps;
  cancelOnClick?: (e: any) => void;
}
//col-span-1 md:col-span-1 col-span-2
export const SchemaForm: React.FC<SchemaFormProps> = ({
  schema,
  validationSchema,
  defaultValues,
  buttonOverrides,
  onSubmit,
  saveText,
  cancelPath,
  cancelOnClick,
  uploadPrefix
}: SchemaFormProps) => {
  const initialValues = Object.keys(schema).reduce((o, key) => {
    const type = schema[key].type;
    const isObject = type === 'Group' || type === 'NestedGroup';
    const isArray = type === 'Array';
    return {
      ...o,
      [key]: Object.prototype.hasOwnProperty.call(defaultValues, key)
        ? defaultValues[key]
        : isObject
        ? {}
        : isArray
        ? []
        : ''
    };
  }, {});

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <div className="flex justify-center">
        <Form
          className="grid grid-cols-2 gap-4 gap-y-8 w-full max-w-[896px]"
          action="#"
          method="POST"
        >
          {Object.keys(schema).map(key => {
            const field = schema[key];
            return (
              <SchemaFormElement
                key={key}
                name={key}
                field={field}
                uploadPrefix={uploadPrefix}
              />
            );
          })}
          <FormActions
            cancelPath={cancelPath}
            cancelOnClick={cancelOnClick}
            saveText={saveText}
            buttonOverrides={buttonOverrides}
          />
        </Form>
      </div>
    </Formik>
  );
};
