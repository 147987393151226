import * as yup from 'yup';
import { AiOutlineInstagram } from 'react-icons/ai';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { ExpandingArrowButton } from 'components/button/ExpandingArrowButton';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { TiSocialFacebook, TiSocialLinkedin } from 'react-icons/ti';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  facebookText: {
    type: ComponentTypes.TextArea,
    label: 'Facebook Text'
  },
  instagramText: {
    type: ComponentTypes.TextArea,
    label: 'Instagram Text'
  },
  linkedinText: {
    type: ComponentTypes.TextArea,
    label: 'Linkedin Text'
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  facebookText: yup.string().required(),
  instagramText: yup.string().required(),
  linkedinText: yup.string().required()
});
export interface SocialLinksProps extends BasicBlockProps {
  facebookText: string;
  instagramText: string;
  linkedinText: string;
}
const SocialLinks = (props: SocialLinksProps) => {
  const { facebookText, instagramText, linkedinText } = props;
  const items = [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/thereelhopeproject',
      icon: TiSocialFacebook,
      buttonLabel: 'The Reel Hope Project',
      copy: facebookText
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/thereelhopeproject/',
      icon: AiOutlineInstagram,
      buttonLabel: '@thereelhopeproject',
      copy: instagramText
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/thereelhopeproject',
      icon: TiSocialLinkedin,
      buttonLabel: 'The Reel Hope Project',
      copy: linkedinText
    }
  ];
  return (
    <BlockWrapper {...props}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <ul
          role="list"
          className="mx-auto pt-20 grid grid-cols-3 gap-x-8 text-center"
        >
          {items.map((item, index) => (
            <li key={index}>
              <a
                href={item.href}
                className="block bg-trhp-teal w-28 h-28 text-center p-4 mx-auto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <item.icon
                  className="h-20 w-20 text-white"
                  aria-hidden="true"
                />
              </a>
              <ExpandingArrowButton
                type="button"
                styletype="tertiary"
                className="my-4 text-sm font-light"
                arrowtype={'single'}
                label={item.buttonLabel}
                onClick={() => window.open(item.href)}
                size={'medium'}
                overrides={{
                  borderColor: '#00C4DE',
                  textColor: '#00C4DE',
                  arrowColor: '#00C4DE',
                  hoverBgColor: 'rgba(0,0,0,.05)',
                  hoverBorderColor: 'rgba(0,0,0,.05)'
                }}
              />
              <p className="font-light text-base">{item.copy}</p>
            </li>
          ))}
        </ul>
      </div>
    </BlockWrapper>
  );
};

export default SocialLinks;
