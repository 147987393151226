import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from '../BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormMapper } from 'modules/submissions/domain/FormSubmissionSchemas';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { SchemaForm } from 'components/forms/SchemaForm';
import { createSubmission } from 'services/submissions';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Parser from 'html-react-parser';

export interface FormBuilderProps extends BasicBlockProps {
  formName: string;
  message?: string;
  mailchimpList?: string;
  formColor?: string;
}

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  formColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Form Background Color',
    required: false
  },
  formName: {
    type: ComponentTypes.Select,
    label: 'Form',
    required: true,
    additionalProps: {
      options: Object.keys(FormMapper).map(f => {
        return { id: f, name: FormMapper[f].name };
      })
    }
  },
  message: {
    type: ComponentTypes.RichTextEditor,
    label: 'Thank You Message',
    required: false
  },
  mailchimpList: {
    type: ComponentTypes.TextField,
    label: 'Mailchimp List ID',
    required: false
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  formName: yup.string().required().min(1)
});

const FormBuilder = (props: FormBuilderProps) => {
  const { formName, message, mailchimpList } = props;
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);

  const formProps = FormMapper[formName];

  const doNotSubmit = () => {
    return false;
  };

  //this is to delay adding the real form action so spam bots will not be able to submit.
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 5000);
  }, []);

  const submitForm = async (values: any) => {
    const valuesStringified = JSON.stringify(values);
    await createSubmission({
      formName,
      data: valuesStringified,
      mailchimpList
    });
    setSubmitted(true);
    document.querySelector('#thankyou')?.scrollIntoView();
  };
  const parsedMessage = message ? Parser(message) : null;

  return (
    <BlockWrapper {...props}>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        id={formProps.id}
      >
        <div
          className={
            'w-3/5 max-[850px]:w-3/4 py-6 mr-auto ml-auto mb-20 mt-10 pl-8 pr-8'
          }
          style={{
            backgroundColor: props.formColor ? props.formColor : undefined
          }}
        >
          {!submitted ? (
            <>
              <div className="uppercase text-center text-2xl pb-6 font-josefin">
                {formProps.name}
              </div>
              <h4 className="italic text-center pb-6 font-josefin">
                {formProps.subtitle}
              </h4>
              {!visible ? (
                <SchemaForm
                  schema={formProps.formSchema}
                  validationSchema={formProps.validationSchema}
                  defaultValues={formProps.defaultValues}
                  saveText={formProps.saveText || 'Submit'}
                  buttonOverrides={formProps.buttonOverrides}
                  onSubmit={doNotSubmit}
                />
              ) : (
                <SchemaForm
                  schema={formProps.formSchema}
                  validationSchema={formProps.validationSchema}
                  defaultValues={formProps.defaultValues}
                  saveText={formProps.saveText || 'Submit'}
                  buttonOverrides={formProps.buttonOverrides}
                  onSubmit={submitForm}
                />
              )}
            </>
          ) : (
            <div className="text-center" id="thankyou">
              {parsedMessage || 'Thank you for your submission!'}
            </div>
          )}
        </div>
      </motion.div>
    </BlockWrapper>
  );
};

export default FormBuilder;
