import * as yup from 'yup';
import { ComponentTypes } from 'common/ComponentTypes';
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { classNames } from 'utils/string';
import CollapsibleItem from './CollapsibleItem';
import Link from 'next/link';
import ResponsiveImage from './ResponsiveImage';

export const BlockFormSchema: FormSchemaType = {
  name: {
    type: ComponentTypes.TextField,
    label: 'Name',
    required: true
  },
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: false
  },
  subTitle: {
    type: ComponentTypes.TextField,
    label: 'Sub Title',
    required: false
  },
  bio: {
    type: ComponentTypes.TextArea,
    label: 'Bio',
    required: false
  },
  email: {
    type: ComponentTypes.TextField,
    label: 'Email',
    required: false
  },
  imageUrl: {
    type: ComponentTypes.FileUpload,
    label: 'Image',
    required: true,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      recommendedSize: '400px x 400px',
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  textAlign: {
    type: ComponentTypes.Select,
    label: 'Text Alignment',
    required: false,
    additionalProps: {
      options: [
        { id: 'text-left', name: 'Left' },
        { id: 'text-center', name: 'Center' },
        { id: 'text-right', name: 'Right' }
      ]
    }
  }
};

export const BlockSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  title: yup.string(),
  subTitle: yup.string(),
  bio: yup.string(),
  email: yup.string(),
  imageUrl: yup.string().required('Image is required'),
  textAlign: yup
    .string()
    .notRequired()
    .oneOf(['text-left', 'text-center', 'text-right'])
});
export interface ProfileProps {
  name: string;
  title?: string;
  imageUrl: string;
  subTitle?: string;
  bio?: string;
  email?: string;
  textAlign: string;
}

const Profile = (props: ProfileProps) => {
  const { name, title, subTitle, bio, imageUrl, email, textAlign } = props;

  return (
    <>
      <div>
        <div className="rounded-full overflow-hidden border border-[rgba(30,54,92,0.19)] min-h-[320px] min-w-[320px]">
          <ResponsiveImage url={imageUrl} alt={'profile_image'} />
        </div>
        <div className={classNames(textAlign, 'pt-4')}>
          <h2 className="uppercase text-xl text-trhp-gray font-normal font-josefin">
            {name}
          </h2>
          <p className="font-light">{title}</p>
          <div className="text-trhp-text-light-gray italic font-light">
            {subTitle}
          </div>
        </div>
      </div>
      {bio && (
        <div>
          <CollapsibleItem label={'Read More'} content={bio} />
        </div>
      )}
      {email && (
        <div className="text-center py-8">
          <span className="rounded-full bg-trhp-teal h-14 w-14 inline-block relative hover:bg-trhp-blue">
            <Link
              href={`mailto:${email}`}
              className="block absolute top-1/2 left-1/2 -translate-y-[50%] -translate-x-[50%]"
            >
              <EnvelopeIcon className={'h-9 w-9 fill-white'} />
            </Link>
          </span>
        </div>
      )}
    </>
  );
};

export default Profile;
