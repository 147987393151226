import { ImPlay2 } from 'react-icons/im';
import React from 'react';
import ResponsiveImage from '../ResponsiveImage';

type PropType = {
  selected: boolean;
  imgSrc: string;
  onClick: () => void;
};

export const Thumb: React.FC<PropType> = props => {
  const { selected, imgSrc, onClick } = props;

  return (
    <div
      className={'embla-thumbs__slide'.concat(
        selected ? ' embla-thumbs__slide--selected' : ''
      )}
    >
      <button
        onClick={onClick}
        className="group embla-thumbs__slide__button text-white"
        type="button"
      >
        <div className="embla-thumbs__slide__img">
          <ResponsiveImage url={imgSrc} alt="video_carousel_thumbnail" />
        </div>
        <div className="button-cover w-full h-full top-0 left-0 absolute bg-black/50 opacity-0 group-hover:opacity-100 z-1 transition-all ease duration-500"></div>
        <ImPlay2 className="h-10 w-10 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] opacity-0 group-hover:opacity-100 z-1 transition-all ease duration-500" />
      </button>
    </div>
  );
};
