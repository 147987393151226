import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import {
  BlockFormSchema as TestimonialBlockFormSchema,
  BlockSchema as TestimonialBlockSchema
} from 'components/blocks/Testimonial';
import Testimonial, { TestimonialProps } from './Testimonial';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    required: true,
    additionalProps: {
      itemSchema: TestimonialBlockFormSchema
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  items: yup.array().of(TestimonialBlockSchema)
});
export interface TestimonialSectionProps extends BasicBlockProps {
  items: TestimonialProps[];
}

const TestimonialSection = (props: TestimonialSectionProps) => {
  const { items } = props;
  return (
    <BlockWrapper {...props}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {items.map((item, index) => (
            <li key={index}>
              <Testimonial {...item} />
            </li>
          ))}
        </ul>
      </div>
    </BlockWrapper>
  );
};

export default TestimonialSection;
