import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import ResponsiveImage from './ResponsiveImage';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  image: {
    type: ComponentTypes.FileUpload,
    label: 'Image',
    required: true,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  image: yup.string().required()
});
export interface BlockProps extends BasicBlockProps {
  image: string;
}

const Image = (props: BlockProps) => {
  const { image } = props;

  return (
    <BlockWrapper {...props}>
      <ResponsiveImage url={image} alt="image" />
    </BlockWrapper>
  );
};

export default Image;
