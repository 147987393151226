import * as yup from 'yup';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';

export const PaddingOption = (dir: string) => {
  const dirPrefix = dir.charAt(0).toLowerCase();
  const sizeOptions = [
    0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 20,
    24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 72, 80, 96
  ];
  return {
    type: ComponentTypes.Select,
    label: dir,
    additionalProps: {
      defaultValue: `p${dirPrefix}-0`,
      options: sizeOptions.map(size => ({
        id: `p${dirPrefix}-${size}`,
        name: size
      }))
    }
  };
};
export const BasicBlockFormSchema: FormSchemaType = {
  status: {
    type: ComponentTypes.Select,
    label: 'Status',
    required: true,
    additionalProps: {
      defaultValue: 'unpublished',
      options: [
        {
          id: 'unpublished',
          name: 'Unpublished'
        },
        {
          id: 'published',
          name: 'Published'
        }
      ]
    }
  },
  containerSize: {
    type: ComponentTypes.Select,
    label: 'Container Size',
    additionalProps: {
      defaultValue: 'w-full',
      options: [
        {
          id: 'w-full',
          name: 'Full Width'
        },
        {
          id: 'w-full max-w-7xl',
          name: '1280'
        },
        {
          id: 'w-full max-w-6xl',
          name: '1152'
        },
        {
          id: 'w-full max-w-5xl',
          name: '1024'
        },
        {
          id: 'w-full max-w-4xl',
          name: '896'
        },
        {
          id: 'w-full max-w-3xl',
          name: '768'
        }
      ]
    }
  },
  padding: {
    type: ComponentTypes.NestedGroup,
    label: 'Padding',
    children: {
      top: PaddingOption('Top'),
      bottom: PaddingOption('Bottom'),
      left: PaddingOption('Left'),
      right: PaddingOption('Right')
    }
  },
  backgroundColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Background Color'
  },
  textColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Text Color'
  }
};

export const ExtendBasicBlockSchema = (
  schema: FormSchemaType
): FormSchemaType => {
  return {
    ...BasicBlockFormSchema,
    ...schema
  };
};

export const BasicBlockValidationSchema = yup.object().shape({
  status: yup.string().required('Status is required'),
  backgroundColor: yup.string(),
  textColor: yup.string(),
  padding: yup.object().shape({
    top: yup.string(),
    bottom: yup.string(),
    left: yup.string(),
    right: yup.string()
  }),
  containerSize: yup.string()
});

export interface BasicBlockProps {
  padding?: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  backgroundColor?: string;
  textColor?: string;
  containerSize?: string;
}
