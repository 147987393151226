import * as yup from 'yup';
import {
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from '../BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { StatesMapProps } from './types';
import dynamic from 'next/dynamic';
import tw from 'tailwind-styled-components';

const Map = dynamic(() => import('./Map'), { ssr: false });

export const StatesMapFormSchema: FormSchemaType = {
  minHeight: {
    type: ComponentTypes.Select,
    label: 'Height',
    additionalProps: {
      defaultValue: 'h-screen',
      options: [
        {
          id: 'h-screen',
          name: 'View Height'
        },
        {
          id: 'h-14',
          name: '56px'
        },
        {
          id: 'h-16',
          name: '64px'
        },
        {
          id: 'h-20',
          name: '80px'
        },
        {
          id: 'h-24',
          name: '96px'
        },
        {
          id: 'h-28',
          name: '112px'
        },
        {
          id: 'h-32',
          name: '128px'
        },
        {
          id: 'h-36',
          name: '144px'
        },
        {
          id: 'h-40',
          name: '112px'
        },
        {
          id: 'h-44',
          name: '176px'
        },
        {
          id: 'h-48',
          name: '192px'
        },
        {
          id: 'h-52',
          name: '208px'
        },
        {
          id: 'h-56',
          name: '224px'
        },
        {
          id: 'h-60',
          name: '240px'
        },
        {
          id: 'h-64',
          name: '256px'
        },
        {
          id: 'h-72',
          name: '288px'
        },
        {
          id: 'h-80',
          name: '320px'
        },
        {
          id: 'h-96',
          name: '384px'
        }
      ]
    }
  },
  defaultColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Default State Color',
    required: true
  },
  activeColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Active State Color',
    required: true
  },
  hoverColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Active State Hover Color',
    required: true
  },
  tooltipColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Tooltip Hover Color',
    required: true
  },
  activeStates: {
    type: ComponentTypes.Array,
    label: 'Active States',
    additionalProps: {
      itemSchema: {
        stateCode: {
          type: ComponentTypes.TextField,
          label: 'State Code (e.g. MN)',
          required: true
        },
        link: {
          type: ComponentTypes.TextField,
          label: 'Link (relative path)',
          required: true
        }
      }
    }
  }
};

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  ...StatesMapFormSchema
});

export const StatesMapBlockSchema = yup.object().shape({
  minHeight: yup.string().required(),
  defaultColor: yup.string().required(),
  activeColor: yup.string().required(),
  hoverColor: yup.string().required(),
  tooltipColor: yup.string().required(),
  activeStates: yup
    .array()
    .of(
      yup.object().shape({
        stateCode: yup.string().required(),
        link: yup.string().required()
      })
    )
    .required()
});
export const BlockSchema =
  BasicBlockValidationSchema.concat(StatesMapBlockSchema);
type StyledMapProps = {
  minHeight: string;
};

const StyledMapContainer = tw.div`
  ${(props: StyledMapProps) => props.minHeight}
  relative
  block
`;
const StatesMap = (props: StatesMapProps) => {
  return (
    <BlockWrapper {...props}>
      <StyledMapContainer minHeight={props.minHeight}>
        <Map {...props} />
      </StyledMapContainer>
    </BlockWrapper>
  );
};

export default StatesMap;
