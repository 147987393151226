import * as yup from 'yup';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { motion } from 'framer-motion';

export const BlockFormSchema: FormSchemaType = {
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: true
  },
  subtitle: {
    type: ComponentTypes.TextField,
    label: 'Sub Title',
    required: true
  },
  copy: {
    type: ComponentTypes.TextArea,
    label: 'Copy',
    required: true
  }
};

export const BlockSchema = yup.object().shape({
  title: yup.string().required(),
  subtitle: yup.string().required(),
  copy: yup.string().required()
});
export interface TestimonialProps {
  title: string;
  subtitle: string;
  copy: string;
}

const Testimonial = (props: TestimonialProps) => {
  const { title, subtitle, copy } = props;

  return (
    <motion.div
      initial={{ opacity: 0, rotateY: '63deg', perspective: '2000px' }}
      whileInView={{ opacity: 1, rotateY: 0, perspective: '0px' }}
      viewport={{ once: true, amount: 1 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0 }}
    >
      <h4 className="text-trhp-gray uppercase text-base font-josefin">
        {title}
      </h4>
      <div className="border-r-8 border-b-8 border-trhp-teal pr-4">
        <p className="text-trhp-light-gray text-xs">{subtitle}</p>
        <div className="text-trhp-gray text-sm">{copy}</div>
      </div>
    </motion.div>
  );
};

export default Testimonial;
