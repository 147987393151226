import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import currency from 'currency.js';

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  showProgress: {
    type: ComponentTypes.Toggle,
    label: 'Show Progress',
    required: true
  },
  progressBackgroundColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Progress Background Color',
    required: false
  },
  progressColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Progress Bar Color',
    required: false
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  showProgress: yup.boolean().required(),
  progressBackgroundColor: yup.string().notRequired(),
  progressColor: yup.string().notRequired()
});

export interface BlockProps extends BasicBlockProps {
  name: string;
  showProgress: boolean;
  goal?: number;
  totalRaised?: number;
  progressBackgroundColor: string;
  progressColor: string;
}

const CampaignHeader = (props: BlockProps) => {
  const {
    name,
    goal,
    totalRaised,
    showProgress,
    progressBackgroundColor,
    progressColor
  } = props;
  const backgroundColor = progressBackgroundColor || '#e2e8f0';
  const barColor = progressColor || '#1e365c';
  let pct = totalRaised && goal ? (totalRaised / goal) * 100 : 0;
  if (pct > 100) pct = 100;

  const hasGoal = goal && goal > 0;
  return (
    <BlockWrapper {...props}>
      <h1 className="text-center uppercase text-2xl lg:text-[3.5rem] font-normal py-4">
        {name}
      </h1>
      {showProgress && (
        <>
          <div className="relative w-full">
            <div className="mb-1 text-left float-left">
              <p className="text-2xl font-semibold mb-0">
                {currency(totalRaised ?? 0).format()}
              </p>
              {hasGoal && (
                <p className="mt-0 flex items-baseline text-sm font-semibold opacity-50">
                  of {currency(goal).format()}
                </p>
              )}
            </div>
            {hasGoal && (
              <div className="mb-1 text-right float-right">
                <p className="text-2xl font-semibold mb-0">{pct}%</p>
                <p className="mt-0 flex items-baseline text-sm font-semibold opacity-50">
                  of Goal
                </p>
              </div>
            )}
            <div className="clear-both"></div>
          </div>
          {hasGoal && (
            <div
              className="w-full h-6 rounded-full flex-grow"
              style={{ backgroundColor: backgroundColor }}
            >
              <div
                className="h-6 bg-blue-600 rounded-full dark:bg-blue-500"
                style={{ backgroundColor: barColor, width: `${pct}%` }}
              ></div>
            </div>
          )}
        </>
      )}
    </BlockWrapper>
  );
};
export default CampaignHeader;
