import { useS3Upload } from 'hooks/useS3Upload';
import React, { useEffect, useState } from 'react';
export interface ResponsiveImageProps {
  url: string;
  alt: string;
  grayScale?: boolean;
  fullHeight?: boolean;
  restrictHeight?: boolean;
}

const ResponsiveImage = (props: ResponsiveImageProps) => {
  const { url, alt, grayScale } = props;
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const { getFileUrl } = useS3Upload({
    bucket: process.env.NEXT_PUBLIC_S3_BUCKET || 'trhp'
  });
  const cleanUrl = (imageUrl: string) => {
    return imageUrl.replace('[', '%5B').replace(']', '%5D');
  };
  const updateImageUrl = async () => {
    const fileUrl = await getFileUrl(cleanUrl(url));
    setImageUrl(fileUrl);
  };
  useEffect(() => {
    if (
      url.includes('pages') ||
      url.includes('campaigns') ||
      url.includes('joblistings') ||
      url.includes('events')
    ) {
      updateImageUrl();
    } else {
      setImageUrl(cleanUrl(url));
    }
  }, [url]);
  if (imageUrl === '') return null;
  return (
    <div
      className={
        props.fullHeight ? 'relative w-full h-full' : 'w-full relative'
      }
      style={{
        ...(grayScale && { filter: 'grayscale(100%)' }),
        ...(props.restrictHeight && {
          height: '100%'
        })
      }}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={imageUrl}
        width="2000"
        height="1000"
        sizes="100vw"
        onLoad={() => setLoaded(true)}
        alt={alt}
        className={`image ${loaded ? 'visible' : 'invisible'}`}
        style={{
          ...(props.fullHeight && {
            objectFit: 'cover',
            minWidth: '100%',
            minHeight: '100%'
          }),
          ...(!props.fullHeight && { width: '100%', height: 'auto' }),
          ...(props.restrictHeight && {
            width: 'auto',
            maxHeight: '100%',
            margin: '0 auto'
          })
        }}
      />
    </div>
  );
};
export default ResponsiveImage;
