import * as yup from 'yup';
import {
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import ResponsiveImage from './ResponsiveImage';
import tw from 'tailwind-styled-components';

const sizeOptions: string[] = [
  'gap-x-0',
  'gap-x-0.5',
  'gap-x-1',
  'gap-x-1.5',
  'gap-x-2',
  'gap-x-2.5',
  'gap-x-3',
  'gap-x-3.5',
  'gap-x-4',
  'gap-x-5',
  'gap-x-6',
  'gap-x-7',
  'gap-x-8',
  'gap-x-9',
  'gap-x-10',
  'gap-x-11',
  'gap-x-12',
  'gap-x-14',
  'gap-x-16',
  'gap-x-20',
  'gap-x-24',
  'gap-x-28',
  'gap-x-32',
  'gap-x-36',
  'gap-x-40',
  'gap-x-44',
  'gap-x-48',
  'gap-x-52',
  'gap-x-56',
  'gap-x-60',
  'gap-x-64',
  'gap-x-72',
  'gap-x-80',
  'gap-x-96'
];

const colOptions: string[] = [
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4'
];

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  gap: {
    type: ComponentTypes.Select,
    label: 'Gap',
    additionalProps: {
      defaultValue: `gap-x-16`,
      options: sizeOptions.map(size => {
        const sizeParts = size.split('-');
        const nameVal = sizeParts[2];
        return {
          id: size,
          name: nameVal
        };
      })
    }
  },
  itemsPerRow: {
    type: ComponentTypes.Select,
    label: 'Items Per Row',
    required: true,
    additionalProps: {
      defaultValue: `grid-cols-2`,
      options: colOptions.map(col => {
        const colParts = col.split('-');
        const nameVal = colParts[2];
        return {
          id: col,
          name: nameVal
        };
      })
    }
  },
  items: {
    type: ComponentTypes.Array,
    label: 'Items',
    required: true,
    additionalProps: {
      itemSchema: {
        imageUrl: {
          type: ComponentTypes.FileUpload,
          label: 'Image',
          required: false,
          additionalProps: {
            autoUpload: true,
            maxFiles: 1,
            tooltip: true,
            allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
          }
        },
        label: {
          type: ComponentTypes.TextField,
          label: 'Label',
          required: false
        },
        subLabel: {
          type: ComponentTypes.TextField,
          label: 'Sub Label',
          required: false
        }
      }
    }
  }
});

const ImageSchema = yup.object({
  imageUrl: yup.string().required(),
  label: yup.string().notRequired(),
  subLabel: yup.string().notRequired()
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  itemsPerRow: yup.string().required(),
  gap: yup.string(),
  items: yup.array().of(ImageSchema)
});

export interface ImageSectionProps {
  gap?: string;
  itemsPerRow: string;
  buttonStyle:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'teal-outlined'
    | 'blue-background';
  includePlayBtn?: boolean;
  items: { imageUrl: string; label?: string; subLabel?: string }[];
}
interface StyledListProps {
  itemsPerRow: string;
  gap: string;
}
const StyledList = tw.ul`
${(props: StyledListProps) => `${props.gap} lg:${props.itemsPerRow}`}
  mx-auto
  mt-20
  grid
  max-w-2xl
  grid-cols-1
  gap-y-16
  sm:grid-cols-2
  lg:mx-0
  lg:max-w-none
  
`;

const ImageSection = (props: ImageSectionProps) => {
  const { items, itemsPerRow, gap = '8' } = props;
  return (
    <BlockWrapper {...props}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <StyledList role="list" gap={gap} itemsPerRow={itemsPerRow}>
          {items.map((item, index) => (
            <li key={index} className="flex items-center mx-auto">
              <div>
                <ResponsiveImage url={item.imageUrl} alt={item.label || ''} />
                {item.label && (
                  <div className="text-center py-6 uppercase text-xl">
                    {item.label}
                  </div>
                )}
                {item.subLabel && (
                  <p className="text-trhp-gray-50 text-center text-lg font-normal">
                    {item.subLabel}
                  </p>
                )}
              </div>
            </li>
          ))}
        </StyledList>
      </div>
    </BlockWrapper>
  );
};

export default ImageSection;
