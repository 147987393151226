/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import { Thumb } from './Thumb';
import { useCallback, useEffect, useState } from 'react';
import Video from '../Video';
import useEmblaCarousel from 'embla-carousel-react';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { ComponentTypes } from 'common/ComponentTypes';
import * as yup from 'yup';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { BlockWrapper } from '../BlockWrapper';
export interface VideoCarouselProps extends BasicBlockProps {
  videos: { url: string; name: string; imageUrl: string }[];
}

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  videos: {
    type: ComponentTypes.Array,
    label: 'Videos',
    required: true,
    additionalProps: {
      itemSchema: {
        url: {
          type: ComponentTypes.TextField,
          label: 'Video URL',
          required: true
        },
        name: {
          type: ComponentTypes.TextField,
          label: 'Video Name',
          required: true
        },
        imageUrl: {
          type: ComponentTypes.FileUpload,
          label: 'Image',
          required: true,
          additionalProps: {
            autoUpload: true,
            maxFiles: 1,
            tooltip: true,
            allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
          }
        }
      }
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  videos: yup.array().of(
    yup.object({
      url: yup.string().required(),
      name: yup.string().required(),
      imageUrl: yup.string().required()
    })
  )
});

const VideoCarousel = (props: VideoCarouselProps) => {
  const { videos } = props;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({ loop: true });

  const scrollPrev = useCallback(
    () => emblaMainApi && emblaMainApi.scrollPrev(),
    [emblaMainApi]
  );
  const scrollNext = useCallback(
    () => emblaMainApi && emblaMainApi.scrollNext(),
    [emblaMainApi]
  );

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaMainApi.canScrollPrev());
    setNextBtnEnabled(emblaMainApi.canScrollNext());
  }, [emblaMainApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect);
    emblaMainApi.on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <BlockWrapper {...props}>
      <div className="embla lg:max-w-[80%] mx-auto p-0">
        <div className="group embla__viewport relative" ref={emblaMainRef}>
          <div className="embla__container">
            {videos.map((video, index) => (
              <div className="embla__slide" key={index}>
                <Video url={video.url} name={video.name} />
              </div>
            ))}
          </div>

          <button
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
            className="hidden sm:block sm:visible absolute top-[50%] pl-5 z-1 -translate-y-[50%] -left-5 opacity-0 group-hover:opacity-100 group-hover:left-5 ease transition-all duration-150"
          >
            <svg
              className="embla__button__svg"
              viewBox="137.718 -1.001 366.563 644"
            >
              <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
            </svg>
          </button>

          <button
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
            className="hidden sm:block sm:visible absolute top-[50%] pr-5 z-1 -translate-y-[50%] -right-5 opacity-0 group-hover:opacity-100 group-hover:right-5 ease transition-all duration-150"
          >
            <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
              <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
            </svg>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-4 pt-4 max-w-[975px] mx-auto">
          {videos.map((video, index) => (
            <Thumb
              onClick={() => onThumbClick(index)}
              selected={index === selectedIndex}
              imgSrc={video.imageUrl}
              key={index}
            />
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default VideoCarousel;
