import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from '../BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { CtaFormSchema, CtaProps, CtaSchema } from '../Cta';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { SideContent } from './SideContent';

export const SideBySideContentFormSchema: FormSchemaType = {
  headline: {
    type: ComponentTypes.RichTextEditor,
    label: 'Headline',
    required: false
  },
  textAlign: {
    type: ComponentTypes.Select,
    label: 'Text Alignment',
    required: false,
    additionalProps: {
      options: [
        { id: 'text-left', name: 'Left' },
        { id: 'text-center', name: 'Center' },
        { id: 'text-right', name: 'Right' }
      ]
    }
  },
  body: {
    type: ComponentTypes.RichTextEditor,
    label: 'Body',
    required: false
  },
  button: CtaFormSchema,
  imageUrl: {
    type: ComponentTypes.FileUpload,
    label: 'Image',
    required: false,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  videoUrl: {
    type: ComponentTypes.TextField,
    label: 'Video URL',
    required: false
  }
};

export const SideBySideFormSchema: FormSchemaType = {
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: false
  },
  textShadow: {
    type: ComponentTypes.Checkbox,
    label: 'Text Shadow',
    required: false
  },
  textColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Text Color',
    required: false
  },
  leftContent: {
    type: ComponentTypes.NestedGroup,
    label: 'Left Side',
    children: SideBySideContentFormSchema
  },
  rightContent: {
    type: ComponentTypes.NestedGroup,
    label: 'Right Side',
    children: SideBySideContentFormSchema
  }
};
export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  ...SideBySideFormSchema
});

export const SideBySideContentSchema = yup.object({
  headline: yup.string().notRequired(),
  headlineSize: yup.string().notRequired(),
  textAlign: yup
    .string()
    .notRequired()
    .oneOf(['text-left', 'text-center', 'text-right']),
  body: yup.string().notRequired(),
  button: CtaSchema.notRequired(),
  imageUrl: yup.string().notRequired(),
  videoUrl: yup.string().notRequired()
});
export const SideBySideSchema = yup.object({
  title: yup.string().notRequired(),
  textShadow: yup.string().notRequired(),
  rightContent: SideBySideContentSchema.notRequired(),
  leftContent: SideBySideContentSchema.notRequired()
});
export const BlockSchema = BasicBlockValidationSchema.concat(SideBySideSchema);
export interface SideBySideProps extends BasicBlockProps {
  leftContent: {
    headline?: string;
    headlineSize?: string;
    textAlign: string;
    body?: string;
    button?: CtaProps;
    imageUrl?: string;
    videoUrl?: string;
  };
  rightContent: {
    headline?: string;
    headlineSize?: string;
    textAlign: string;
    body?: string;
    button?: CtaProps;
    imageUrl?: string;
    videoUrl?: string;
  };
  animate?: boolean;
  title?: string;
  textShadow?: boolean;
}

const SideBySide = (props: SideBySideProps) => {
  const { leftContent, rightContent, animate, title, textShadow, ...rest } =
    props;

  return (
    <BlockWrapper {...rest}>
      <div className="h-auto">
        {title && (
          <h2 className="text-center w-full text-5xl uppercase pb-20 pt-4">
            {title}
          </h2>
        )}
        <div className="lg:flex">
          <SideContent
            {...leftContent}
            animate={animate}
            textShadow={textShadow}
          />
          <SideContent
            {...rightContent}
            animate={animate}
            textShadow={textShadow}
          />
        </div>
      </div>
    </BlockWrapper>
  );
};

export default SideBySide;
